<template>
  <div class="thanks">
    <div class="content">
      <img loading="lazy" src="@/assets/images/ActExpo/Thanks/bolt_branding.svg?external" alt="Bolt Logo." />
      <h1>Thank you!</h1>
      <a href="../../pdf/Commercial_Brochure.pdf" rel="noopener" target="_blank" class="button fixed-button"
        >VIEW BROCHURE</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'Thanks',
  metaInfo: {
    meta: [{ name: 'robots', content: 'noindex' }],
  },
};
</script>

<style scoped lang="scss">
.thanks {
  padding: 43px 20px 293px 20px;
  text-align: center;
  font-family: 'Omnes', Arial, Helvetica, sans-serif;
  @media screen and (max-width: 768px) {
    padding-bottom: 100px;
  }
  h1 {
    font-weight: normal;
    font-size: 48px;
    color: $c-primary;
    letter-spacing: 2.4px;
    margin: 0;
  }
  a {
    font-size: 14px;
    font-weight: $fw-medium;
    padding: 14px 70px;
    letter-spacing: 0.7px;
  }
}
</style>
